<template>
  <div>
    <a-card>
      <template slot="title">
        <strong class="mr-4">Data Golongan Deposito</strong>
        <!-- <a-range-picker class="ml-3" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
        <!-- <a-button
          type="button"
          class="btn btn-outline-primary ml-2"
          @click="connector('nonanggota')"
        >
          Tambah Non Anggota
        </a-button> -->
        <a-button
          type="button"
          class="btn btn-outline-primary "
          @click="showModal('create', {})"
        >
          Tambah
        </a-button>
            <a-button
              class="btn btn-outline-success ml-2"
              @click="getAllData(true)"
              >Refresh Data</a-button
            >
      </template>
      <a-table
        :scroll="{ x:700 }"
        :columns="columns"
        :dataSource="data"
        :pagination="{
          hideOnSinglePage:true,
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }"
        size="small"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px;"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px;"
            @click="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px;"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template
          slot="customRender"
          slot-scope="text, record, index, column"
        >
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(
                  new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                )"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <span slot="tags" slot-scope="tags">
          <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a href="javascript:void(0)" class="text-warning" @click="showModal('update', record)">
            <a-icon type="edit"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
            <a-icon type="delete"></a-icon>
          </a>
          <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
        </span>
        <span slot="jenis_identitas" slot-scope="text, record">
          {{ findRelationData(record, 'masterJenisIndentitas', 'id', 'jenis_identitas', 'keterangan') }}
        </span>
        <span slot="tgl" slot-scope="text, record">
          {{ formatDate(record.tgl) }}
        </span>
      </a-table>
    </a-card>
    <a-modal :maskClosable="false" v-model="formvisible" title="Form Golongan Deposito" :width="600" :dialogStyle="{ top: '30px' }">
      <template slot="footer">
        <a-button class="" type="danger" @click="handleCancel">Cancel</a-button>
        <a-button class="" type="primary" @click="handleSubmit">Submit</a-button>
      </template>
      <div class="row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <label class="mt-1">Kode</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
          <a-input class="" v-model="modalInput.kode"></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Keterangan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input class="" v-model="modalInput.keterangan"></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Bunga</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <cleave
            :options="{ numeral: true }"
            class="ant-input"
            :raw="true"
            style="width: 200px;"
            v-model="modalInput.bunga"
            placeholder="Bunga"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Lama</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <cleave
            :options="{ numeral: true }"
            class="ant-input"
            :raw="true"
            style="width: 200px;"
            v-model="modalInput.lama"
            placeholder="Lama"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Rekening</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            placeholder="Ketikan Rekening"
            label="label"
            v-model="modalInput.rekening_id"
            :options="masterRekening"
            :reduce="(tes) => tes.id"
            @input="connectValue('rekening_id', 'rekening')"
          />
          <!-- <div class="row">
            <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">

            </div>
            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
          <v-select
            placeholder="Keterangan"
            label="keterangan"
            v-model="modalInput.rekening"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
            @input="connectValue('rekening', 'rekening_id')"
          />
            </div>
          </div> -->
            <!-- :selectable="(option) => !option.kode.includes('')" -->
          <a-input style="display: none;"
            :readOnly="true" class="" v-model="modalInput.rekening"></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">R. Bunga</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            placeholder="Ketikan Rekening"
            label="label"
            v-model="modalInput.rekeningbunga_id"
            :options="masterRekening"
            :reduce="(tes) => tes.id"
            @input="connectValue('rekeningbunga_id', 'rekeningbunga')"
          />
          <a-input style="display: none;"
            :readOnly="true" class="" v-model="modalInput.rekeningbunga"></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">R. Pajak Bunga</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            placeholder="Ketikan Rekening"
            label="label"
            v-model="modalInput.rekeningpajakbunga_id"
            :options="masterRekening"
            :reduce="(tes) => tes.id"
            @input="connectValue('rekeningpajakbunga_id', 'rekeningpajakbunga')"
          />
          <a-input class="" style="display: none;"
            :readOnly="true" v-model="modalInput.rekeningpajakbunga"></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Pajak Bunga</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <cleave
            :options="{ numeral: true }"
            class="ant-input"
            :raw="true"
            style="width: 200px;"
            v-model="modalInput.pajakbunga"
            placeholder="Pajak Bunga"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Cadangan Bunga</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input class=""
            v-model="modalInput.cadanganbunga"
          ></a-input>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import Cleave from 'vue-cleave-component'
// import { mapState } from 'vuex'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  {
    title: 'Action',
    scopedSlots: { customRender: 'action' },
    fixed: 'left',
    width: 70,
  },
  {
    title: 'Kode',
    dataIndex: 'kode',
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.kode.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Keterangan',
    dataIndex: 'keterangan',
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.keterangan.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Bunga',
    dataIndex: 'bunga',
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.bunga.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Lama',
    dataIndex: 'lama',
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.lama.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening',
    dataIndex: 'rekening',
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekening.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Bunga',
    dataIndex: 'rekeningbunga',
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningbunga.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Pajak Bunga',
    dataIndex: 'rekeningpajakbunga',
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningpajakbunga.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Pajak Bunga',
    dataIndex: 'pajakbunga',
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.pajakbunga.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Cadangan Bunga',
    dataIndex: 'cadanganbunga',
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.cadanganbunga.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  components: {
    cleave: Cleave,
  },
  computed: {
    // ...mapState(['settings', 'user']),
  },
  created () {
    this.getAllData()
  },
  /* Data variable vue */
  data() {
    return {
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'Tabletabungan',
      visible: false,
      allMaster: [],
      masterstatuskawin: [],
      masterkelamin: [],
      data,
      columns,
      datenow: '',
      componentKey: 0,

      // Modal property
      action: null,
      formvisible: false,
      editdata: {},
      masterRekening: [],
      modalInput: {
        kode: '',
        keterangan: '',
        bunga: '',
        lama: '',
        rekening: '',
        rekening_id: '',
        rekeningbunga: '',
        rekeningbunga_id: '',
        rekeningpajakbunga: '',
        rekeningpajakbunga_id: '',
        pajakbunga: '',
        cadanganbunga: '',
      },
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    // localStorage.removeItem('tabungankuedittabungandata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    // this.getAgamaF()
  },
  methods: {
    moment,
    selectChanged(firstInput) {
      console.log('this.modalInput[firstInput]', this.modalInput[firstInput])
      console.log('firstInput', firstInput)
    },
    connectValue(firstInput, secondInput) {
      var target = this.masterRekening.findIndex(
        (x) => x.id === this.modalInput[firstInput],
      )
      console.log('target', target)
      // console.log('target', target)
      if (target !== -1) {
        // console.log('this.masterRekening[target]', this.masterRekening[target])
        this.modalInput[secondInput] = this.masterRekening[target].kode
      }
    },
    async showModal(action, data) {
      var masterRekening = await lou.customUrlGet2('master_akuntansi')
      if (masterRekening) {
        this.masterRekening = []
        // this.masterRekening = this.$g.clone(masterRekening.data)
        masterRekening.data.forEach(element => {
          var nobj = {
            label: element.kode + ' - ' + element.keterangan,
            ...element,
          }
          this.masterRekening.push(nobj)
        })
        this.formvisible = true
        this.action = action
        if (action === 'update') {
          // console.log('data', data)
          this.modalInput = this.$g.clone(data)
          this.editdata = data
        }
      } else {
        lou.shownotif('error', 'Master akuntansi bermasalah silahkan hubungi admin!', 'error')
      }
    },
    async getAllData(onclick = false) {
      var response = await lou.customUrlGet2('golongandeposito')
      if (response) {
        this.data = response.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      }
      // console.log('this.data', this.data)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2('golongandeposito/' + deldata.id)
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    // Modal function
    async handleSubmit() {
      var fd = this.$g.clone(this.modalInput)
      var res = false
      if (this.action === 'update') {
        res = await lou.customUrlPut2('golongandeposito', fd)
      } else {
        res = await lou.customUrlPost2('golongandeposito', fd)
      }
      if (res) {
        this.getAllData()
        this.handleCancel()
      }
    },
    handleCancel() {
      this.formvisible = false
      this.action = 'create'
      this.modalInput = {
        kode: '',
        keterangan: '',
        bunga: '',
        lama: '',
        rekening: '',
        rekening_id: '',
        rekeningbunga: '',
        rekeningbunga_id: '',
        rekeningpajakbunga: '',
        rekeningpajakbunga_id: '',
        pajakbunga: '',
        cadanganbunga: '',
      }
    },
    filterOption(input, option) {
      // console.log('option.componentOptions.children', option.componentOptions.children)
      // var text = option.componentOptions.children[0].data.domProps.innerHTML + option.componentOptions.children[1].text
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
